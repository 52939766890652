.ol_content li{
	line-height: 1.375; 
    margin-bottom: 0.25rem; 
    padding-left: 0.5rem;
}

.printable * {
  display: none;
}

@media print {
  .page-break{page-break-after: always;}
  .printable * {
	  display: block;
   }
}